import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "scroll" ]

  smoothly(event) {
    event.preventDefault();
    event.stopPropagation();

    var hashLink = event.target.hash;

    if ($(hashLink).length) {
      $(function () {
          // *only* if we have anchor on the url
          // smooth scroll to the anchor id
          $('html, body').animate({
            scrollTop: $(hashLink).offset().top - 70
          }, 2000);
      });
    }
  }

  smooth(event) {
    $('.collapse.show').removeClass('show')
    const offset = this.scrollTarget.dataset['offset']

    const a = $(event.target).closest('a')
    if (a) {
      const url = a.attr("href")
      const anchor = url.substring(url.indexOf("#") + 1)
      if ($('#' + anchor).length) {
        $("html, body").animate({ scrollTop: $('#' + anchor).offset().top - offset }, 800)
      }
    }

    // const collapseTriggerLink = $(event.target).data("trigger-click")
    // const collapse = $(event.target).data("collapse")
    //
    // if (collapse) {
    //   $(collapse).removeClass().addClass('collapse')
    // }
    //
    // if (collapseTriggerLink) {
    //   $(collapseTriggerLink).addClass('collapsed')
    // }
  }
}
