import { Controller } from "stimulus"
import Trix from "trix"

export default class extends Controller {
  static targets = [ "output" ]

  connect() {
    this.editor = this.outputTarget.editor
    this.setupTrixToolbar()
  }

  setupTrixToolbar() {
    document.addEventListener("trix-file-accept", (e) => {
      if (e.target == this.outputTarget) {
        e.preventDefault()
      }
    })
  }
}
